<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "PRODUCT COLLECTIONS",
      items: [
        {
          text: "PRODUCT COLLECTIONS LIST",
          href: "/collection-products"
        },
        {
          text: "PRODUCT COLLECTIONS ADD",
          active: true
        }
      ],
      checkbox:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "#" },
        { key: "pimage", sortable: false, label: "PRODUCT IMAGE",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "title", sortable: true, label: "PRODUCT NAME",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "listSubCategory", sortable: true, label: "PRODUCT TYPE",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "variants[0].sku", sortable: true, label: "PRODUCT SKU",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "variants[0].stock", sortable: true, label: "STOCK",thStyle: { color: "black", "font-size":"16px"}  },

      ],
      productCollection: {
        collectionId: ""
      },

      submitted: false,
      collectionData: [],
      productData: [],

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.productData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.productData.length;

    Master.productList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.productData = res.data.data;
    });

    Master.collectionList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.collectionData = res.data.data;
    })


  },
  validations: {
    productCollection: {
      collectionId: { required },
    },
    checkbox: { required }
  },
  methods: {
    /**
     * Edit Color Modal
     */
    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.productCollection.$invalid) {
        return;
      } else {
        Master.productCollectionAdd(
            {
              collectionId : this.productCollection.collectionId,
              products : this.checkbox
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 2000
          });
          // this.successMessage = res.data.message;
          // this.$bvToast.toast(""+res.data.message+"", {
          //   title: `Success Message`,
          //   variant: 'success',
          //   solid: true
          // });
          // Master.colorList({
          //   trash: 0
          // }).then((res) => {
          //   console.log(res)
          //   this.colorData = res.data.data;
          // })
          this.showmodal = false;
          this.$router.push({path: '/collection-products'})
        }).catch((err) => {
          console.log(err)
          // this.$bvToast.toast(""+err.response.data.error.name[0]+"", {
          //   title: `Error Message`,
          //   variant: 'danger',
          //   solid: true
          // });
          // this.errorMessage = err.response.data.error.name[0];
          this.showmodal = true;
        })

      }
      this.submitted = false;
      this.productCollection.productId = [];
      this.productCollection.collectionId = "";
    },
    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editColor.$invalid) {
        return;
      } else {
        Master.colorUpdate(
            {
              id: this.editColor.id,
              name : this.editColor.name,
              code : this.editColor.code,
              status : this.editColor.status
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          this.$bvToast.toast(""+res.data.message+"", {
            title: `Success Message`,
            variant: 'success',
            solid: true
          });
          Master.colorList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.colorData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          // this.errorMessage = err.response.data.errors;
          this.editshowmodal = true;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <label for="subMenuId">COLLECTIONS *</label>
                <select class="form-control col-md-3"
                        id="subMenuId"
                        v-model="productCollection.collectionId"
                        :class="{ 'is-invalid': submitted && $v.productCollection.collectionId.$error }"
                        style="text-transform: uppercase !important;">
                  <option value="">SELECT COLLECTION</option>
                  <option v-for="collection in collectionData" :value="collection.id" :key="collection.id">{{collection.name}}</option>
                </select>
                <div v-if="submitted && $v.productCollection.collectionId.$error" class="invalid-feedback">
                  <span v-if="!$v.productCollection.collectionId.required">COLLECTION IS REQUIRED</span>
                </div>
              </div>

              <div class="form-group">
                <label for="subMenuId">PRODUCTS *</label>
                <div v-if="submitted && $v.checkbox.$error" class="error badge" style="font-size: 80%; margin-left: 10px; margin-top: 10px">
                  <span v-if="!$v.checkbox.required">PLEASE SELECT ATLEST ONE PRODUCT</span>
                </div>
                <b-tabs nav-class="nav-tabs-custom">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
<!--                        <label class="d-inline-flex align-items-center">-->
<!--                          Show&nbsp;-->
<!--                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries-->
<!--                        </label>-->
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                              v-model="filter"
                              type="search"
                              class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                <div class="table-responsive">
                  <b-table
                      class="table-centered"
                      :items="productData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      sort-icon-left
                  >

                    <template  v-slot:cell(pimage) = "data">
                      <img
                          v-if="data.item.variants[0] != null"
                          :src="data.item.variants[0].images[0].imageUrl"
                          alt="product-img"
                          title="product-img"
                          class="avatar-md"
                      />
                    </template>
                    <template v-slot:cell(title)="row">
                      <p class="badge font-size-12">
                        {{row.item.title}}
                      </p>
                    </template>
                    <template v-slot:cell(listSubCategory)="row">
                      <p class="badge font-size-12">
                        {{row.item.listSubCategory}}
                      </p>
                    </template>
                    <template v-slot:cell(variants[0].sku)="row">
                      <p class="badge font-size-12" v-if="row.item.variants[0] != null">
                        {{row.item.variants[0].sku}}
                      </p>
                    </template>
                      <template v-slot:cell(variants[0].stock)="row">

                              <p class="badge font-size-12" v-if="row.item.variants[0] != null">
                                  <span v-if="row.item.variants[0].stock > 0">{{row.item.variants[0].stock}}</span>
                                  <span v-else class="badge-soft-danger badge font-size-9">OUT OF STOCK</span>
                              </p>

                      </template>
                    <template v-slot:cell(checkbox)="row">
                      <div class="form-check mb-3">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkbox"
                            :id="`customercheck${row.index}`"
                            :value="row.item.id"
                        />
                        <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                </b-tabs>
<!--                <div class="row">-->
<!--                  <div class="col-md-4" v-for="(product,index) in productData" :key="product.id">-->
<!--                    <div class="custom-control custom-checkbox">-->
<!--                      <input :id="'invalidCheck_filter'+index" type="checkbox" v-bind:value="product.id" v-model="productCollection.productId"   class="custom-control-input"   />-->
<!--                      <label-->
<!--                          class="custom-control-label"-->
<!--                          :for="'invalidCheck_filter'+index"-->
<!--                      >{{product.title}}</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="submitted && $v.productCollection.productId.$error" class="error" style="font-size: 80%; margin-left: 10px; margin-top: 10px">-->
<!--                    <span v-if="!$v.productCollection.productId.required">PRODUCTS ARE REQUIRED</span>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <hr/>
              <div class="text-right">
                <button type="submit" class="btn btn-success">SAVE</button>
                <router-link to="/collection-products"><b-button class="ml-1" variant="danger">BACK</b-button></router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>